:root {
  --bgwhite: rgb(247, 240, 240);
}

body {
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue", sans-serif;
  background-color: var(--bgwhite);
}

a {
  color: black;
  text-decoration: none;
}

@media only screen and (min-width: 768px) {
  a:hover {
    text-decoration: none;
  }
}

@font-face {
  font-family: "Helvetica Neue LT Std";
  src: "HelveticaNeueLTStd-Bd.woff2" format("woff2"),
    "HelveticaNeueLTStd-Bd.woff" format("woff"),
    "HelveticaNeueLTStd-Bd.ttf" format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Neue LT Std";
  src: "HelveticaNeueLTStd-Lt.woff2" format("woff2"),
    "HelveticaNeueLTStd-Lt.woff" format("woff"),
    "HelveticaNeueLTStd-Lt.ttf" format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
